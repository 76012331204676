import Pagination from "rc-pagination";
import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Link } from "react-router-dom";

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);
  
const RcExchangeReport = () => {
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(false)
    const [rcAddshow, setRCAddShow] = useState(false);

    const handleRCAddClose = () => setRCAddShow(false);
    const handleRCAddShow = () => setRCAddShow(true);


    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = dateRanges;

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Exchange Report <span>123</span></h3>
                <div className="page-heading-right">

                    <SelectPicker
                        cleanable={false}
                        data={data}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Date"
                    />

                    <DateRangePicker initialSettings={{ 
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                        }}>
                        <input type="text" name="date" placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
                    </DateRangePicker>

                    <SelectPicker
                        cleanable={false}
                        data={data}
                        searchable={false}
                        defaultValue={10}
                        className="wv-100 my-1 ms-3"
                    />

                    <Button onClick={handleRCAddShow} variant="primary" className="my-1 ms-3">Add New</Button>
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {/* {loading && <div className="loader table-loader"></div>} */}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="31%" className="text-center">Vendor Name</th>
                                    <th width="12%" className="text-center">Type</th>
                                    <th width="30%" colSpan={2} className="text-center">API</th>
                                    <th width="12%" className="text-center">Limit</th>
                                    <th width="10%" className="text-center">Show Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>1</td>
                                    <td className="text-center">Stephanie J. Chapman</td>
                                    <td className="text-center">Daily</td>
                                    <td className="text-center">/rc-search</td>
                                    <td className="text-center">
                                        <Link to="/rc-exchange-report/carinfo-rc-count" className="btn btn-icon btn-transparent btn-success">
                                            <i className='bx bx-copy'></i>
                                        </Link>
                                    </td>
                                    <td className="text-center">10,000</td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <Link to="/rc-exchange-report/carinfo-rc-count" className="btn btn-icon btn-transparent btn-danger">
                                                <i className='bx bx-receipt'></i>
                                            </Link>
                                            <span className='border-start py-2 mx-2'></span>
                                            <Link to="/rc-exchange-report/view" className="btn btn-icon btn-transparent btn-primary">
                                                <i className='bx bx-show'></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found RC Exchange Report</p>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </Table>
                        <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={100}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Modal show={rcAddshow} size="lg" centered scrollable onHide={handleRCAddClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Vender</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3">
                        <Col md={6}>
                            <Form.Label>Vendor Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                className="mt-2"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Vendor Name Field Is Require
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={6}>
                            <Form.Label>Select Type</Form.Label>
                            <SelectPicker
                                block
                                cleanable={false}
                                data={data}
                                searchable={false}
                                placeholder="Select Type"
                                className="mt-2"
                            />
                            <Form.Control.Feedback type="invalid">
                                Select Type Field Is Require
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={6}>
                            <Form.Label>Limit</Form.Label>
                            <Form.Control
                                type="text"
                                name="limit"
                                className="mt-2"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Limit Field Is Require
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={6}>
                            <Form.Label>API</Form.Label>
                            <Form.Control
                                type="text"
                                name="api"
                                className="mt-2"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                API Field Is Require
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={12} className="text-center">
                            <Button variant="primary my-3 px-5">Add Vender</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default RcExchangeReport