import React from "react";

const Logo = () => {
    return (
        <>
            <img src="/logo/logo.svg" className="logo img-fluid" alt="VehicleInfo - RC"/>
            <img src="/logo/logodark.svg" className="logodark img-fluid d-none" alt="VehicleInfo - RC"/>
        </>
     );
};

export default Logo;