import Cookies from 'js-cookie'
import { API } from '../../App';



export const getServices = async (payload,token) => {
      let result
      try {
            result = await API.post("/get_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
export const serviceUpdate = async (payload,token) => {
      let result
      try {
            result = await API.post("/store_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
export const deleteService = async (payload,token) => {
      let result
      try {
            result = await API.post("/delete_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
