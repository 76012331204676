import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "js-cookie";
import Layout from "../../layout/Layout";
import { toast } from "react-toastify";

var reloadId = [];
const RoleView = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams();
  const navigate = useNavigate()
  const [id, setid] = useState({
    id: params.id,
  });

  const [role, setRole] = useState({
    name: "",
    permissionId: [],
    permissionArr: [],
    id: ""
  })
  const [data, setData] = useState([])
  const getData = async (id) => {
    const result = await API.post(`/permission/view-role`, { role_id: id }, { headers: { authorization: `${token}` } })
    setRole({
      name: result?.data?.data[0]?.UserHasRole?.name,
      permissionId: result?.data?.data?.map(item => item?.UserHasPermission?.id),
      permissionArr: result?.data?.data?.map(item => item?.UserHasPermission?.name),
      id: result?.data?.data[0]?.UserHasRole?.id
    })
    setid({ id: result?.data?.data[0]?.UserHasRole?.id })
  }

  const moduleList = async (req, res) => {
    reloadId = []
    const result = await API.post("/permission/roles", {}, { headers: { authorization: `${token}` } })
    result.data.data.map(item => {
      reloadId.push({ label: item.name, value: item.id })
    })
    setData(reloadId)
    // result.data.data.map((val) => {
    //   reloadId.push(val.id)
    // })
  }

  useEffect(() => {
    getData(id.id)
    moduleList()
  }, []);

  const editFunc = () => {
    sessionStorage.setItem('path', `/Role/Edit/${role.id}`)
    navigate(`/role/edit/${role.id}`)
  }

  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(role.id)
          reloadId.splice(ind, 1)
          const formdata = new FormData()
          formdata.append("id", role.id)
          const deletedResult = await API.post(`/permission/roles-delete/${params.id}`, {}, { headers: { authorization: `${token}` } });
          if (deletedResult.data.status == true) {
            navigate(`/role`)
            toast.success(deletedResult.data.response_message)
          } else {
            toast.error(deletedResult.data.response_message)
          }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };

  return (
    <>
      <Layout sidebar={true}>
        <div className="page-heading backbtn">
          <h3>
            <Link to="/role" className="btn btn-transparent btn-icon me-2">
              <i className="bx bx-chevron-left"></i>
            </Link>
             Role View
          </h3>
          <div className="page-heading-right">
            <SelectPicker
              data={data}
              defaultValue={id.id}
              value={id.id}
              cleanable={false}
              placement="bottomEnd"
              className="wv-200 my-1 ms-3"
              placeholder="Select Name"
              onChange={(e) => getData(e)}
            />
            <Button variant="primary ms-3 my-1" value="edit" onClick={editFunc}>
              Edit
            </Button>
            <Button variant="danger ms-3 my-1 btn-icon-lg"><i className="bx bx-trash-alt" onClick={(i) => Deleteproject()}></i></Button>
          </div>
        </div>
        <div className="page-content">
          <Card className="radiustop-0">
            <Card.Body>
              <Row>
                <Col md={4}>
                  <div className="mb-4">
                    <p className="mb-0 fw-bold">Name</p>
                    <span>{role.name}</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-4">
                    <p className="mb-2 fw-bold">Permission</p>
                    <Row className="g-2">
                      {
                        role?.permissionArr?.length>0&&role?.permissionArr?.map((item, index)=>{
                          return(<Col lg={3}>
                              <span>{item}</span>
                          </Col>)
                        })
                      }
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Layout>
    </>
  )
}

export default RoleView