import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import { Link, useNavigate, useParams } from "react-router-dom";
import { deletePartner, getPartnerData } from "./service";
import Swal from "sweetalert2";
import $ from "jquery";
import ReactCodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import { javascript } from "@codemirror/lang-javascript";
import { okaidia } from "@uiw/codemirror-theme-okaidia";
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";


const dataType = ['cUrl'].map(item => ({ label: item, value: item }));


const RcPartnerView = () => {
    const partnerUrl = process.env.REACT_APP_PARTNER_URL
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setIsLoading] = useState(false)
    const [partner, setPartner] = useState([])
    const [theme, setTheme] = useState(okaidia); // Dummy theme value for now
    const [selectedLanganguage, setSelectedLanganguage] = useState([]);
    const { userPermission, token } = useContext(AuthContext)


    useEffect(() => {
        if (partner?.services) {
            setSelectedLanganguage(partner.services.map(() => 'cUrl'));
        }
    }, [partner?.services]);
    const handleLangChange = (index, lang) => {
        const newSelectedLangs = [...selectedLanganguage];
        newSelectedLangs[index] = lang;
        setSelectedLanganguage(newSelectedLangs);
    };

    const fetchPartner = async () => {
        setIsLoading(true)
        try {
            const formData = new FormData()
            formData?.append("id", id)
            const response = await getPartnerData(formData, token)
            if (response?.status) {
                setPartner(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    /**delete service*/
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                try {

                    if (result.isConfirmed) {
                        const formData = new FormData()
                        formData?.append("id", id)
                        const deletedata = await deletePartner(formData, token)
                        navigate("/rc-partner")
                    } else {
                        count = 10
                        clearInterval(swalCountdownInterval)
                    }
                } catch (error) {
                    console.log('error', error)
                }
            });
    };

    useEffect(() => {
        fetchPartner()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading backbtn">
                <h3>
                    <Link to="/rc-partner" className='btn btn-transparent btn-icon me-2'>
                        <i className='bx bx-chevron-left' ></i>
                    </Link>
                    RC Partner Report View
                </h3>
                <div className="page-heading-right">
                    {/* <SelectPicker
                        data={dataType}
                        cleanable={false}
                        className="wv-200 my-1 ms-3"
                        placeholder="Select Categories"
                        placement="bottomEnd"
                    /> */}

                    {userPermission["RC-Partner"]?.edit == 1 &&
                        <Link to={`/rc-partner/edit/${id}`} className="btn btn-primary ms-3 my-1">Edit</Link>
                    }
                    {userPermission["RC-Partner"]?.delete == 1 &&
                        <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={() => handleDelete(id)} type="button"><i className="bx bx-trash-alt"></i></Button>
                    }

                </div>
            </div>
            <div className='page-content'>
                <Card className="mb-4 radiustop-0">
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Partner Name</p><span>{partner?.name}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Email</p><span>{partner?.email}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Phone No.</p><span>{partner?.phone}</span>
                                </div>
                            </Col>
                            <Col md={1}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p><span>{partner?.status == 1 ? "On" : "Off"}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>API KEY</p><span>{partner?.api_key}</span>
                                </div>
                            </Col>
                        </Row>



                    </Card.Body>
                </Card>
                {partner?.services?.map((item, index) => {
                    // setSelectedLanganguage([...selectedLanganguage, "cUrl"])
                    const codeSnippets = {
                        cUrl: `curl --location '${partnerUrl + item?.api_endpoint}'
--header 'x-api-key: {your_API_key}'
${item?.header ? `--header '${item?.header}'` : ""}
${item?.body ? item?.body?.split(",")?.map((el) => `--form '${el}'`).join("\n") : ""}`,




                        c: `#include <stdio.h>
int main() {
   printf("Hello, World!");
   return 0;
}`,
                        'c++': `#include <iostream>
using namespace std;
int main() {
   cout << "Hello, World!";
   return 0;
}`,
                        python: `import requests
url = '${item?.api_endpoint}'
headers = "${item?.header}"
data = '${item?.body}'
response = requests.post(url, headers=headers, json=data)
print(response.json())`
                    };
                    return (
                        <Card key={index} className="mb-4">
                            <Card.Header className="border-bottom">
                                <h4 className="fw-600 mb-0">
                                    {item?.service_name}
                                </h4>
                            </Card.Header>
                            <Card.Body>
                                <Row className="g-4">
                                    <Col md={6}>
                                        <Row className="g-3">
                                            <Form.Control
                                                type="text"
                                                value={`Type: ${item?.limit_type}`}
                                                disabled
                                            />
                                            <Form.Control
                                                type="text"
                                                value={`Limit: ${item?.api_limit}`}
                                                disabled
                                            />
                                            <Form.Control
                                                type="text"
                                                value={`Status: ${item?.status == 1 ? "On" : "Off"}`}
                                                disabled
                                            />
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <SelectPicker
                                                cleanable={false}
                                                data={dataType}
                                                searchable={false}
                                                placeholder="Select Language"
                                                value={selectedLanganguage[index]}
                                                onChange={(lang) => handleLangChange(index, lang)}
                                            />
                                            <Button className="btn btn-icon btn-transparent"
                                                onClick={() => navigator.clipboard.writeText(codeSnippets[selectedLanganguage[index]]).then((e) => toast.success("Copy Successfully"))}
                                            >
                                                <i className='bx bx-copy-alt'></i>
                                            </Button>
                                        </div>
                                        <div className='mb-4'>
                                            <ReactCodeMirror
                                                value={codeSnippets[selectedLanganguage[index]]}
                                                theme={theme}
                                                readOnly
                                                editable={false}
                                                extensions={[selectedLanganguage[index] === 'python' ? python() : javascript()]}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>)
                })}
            </div>
        </Layout>
    )
}

export default RcPartnerView