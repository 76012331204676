import { createContext, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import './style/bootstrap.css';
import 'boxicons/css/boxicons.css';
import 'bootstrap-daterangepicker/daterangepicker.css'
import './style/App.css';
import './style/utilities.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';

import CarinfoRcCount from './pages/RcPartner/CarinfoRcCount';
import CarinfoCount from './pages/RcExchangeReport/CarinfoRcCount';
import RcExchangeReport from './pages/RcExchangeReport/RcExchangeReport';
import RcExchangeReportAdd from './pages/RcExchangeReport/RcExchangeReportAdd';
import RcExchangeReportView from './pages/RcExchangeReport/RcExchangeReportView';
import RcPartner from './pages/RcPartner/RcPartner';
import RcPartnerAdd from './pages/RcPartner/RcPartnerAdd';
import RcPartnerView from './pages/RcPartner/RcPartnerView';
import RcServices from './pages/RcServices/RcServices';
import RcServicesView from './pages/RcServices/RcServicesView';
import RcServicesAdd from './pages/RcServices/RcServicesAdd';


import Module from "./pages/Module/Module"
import AddModule from "./pages/Module/ModuleAdd"
import ViewModule from "./pages/Module/ModuleView"
import EditModule from "./pages/Module/ModuleEdit"

import Role from './pages/Role/Role';
import RoleAdd from './pages/Role/RoleAdd';
import ViewRole from './pages/Role/RoleView';

import User from './pages/Permission/User';
import AddUser from './pages/Permission/UserAdd';
import ViewUser from './pages/Permission/UserView';
import EditUser from './pages/Permission/UserEdit';
import Protected from './Component/Protected.js';
import PrivetRoutes from './Component/PrivetRoutes.js';
import { AuthContext } from './AppContext.jsx';

export const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })


const App = () => {
    const { userPermission, theme, setTheme } = useContext(AuthContext)

    return (
        <>
            <>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Protected />}>
                            <Route path="/" element={<Login />} />
                        </Route>
                        <Route element={<PrivetRoutes />}>
                            <Route path="/Home" element={<Home />} />
                            <Route path="*" element={<PageNotFound />} />

                            {userPermission["RC-Exchange-Report"]?.view == 1 &&
                            <Route path="/rc-partner/partner-rc-count/" element={<CarinfoRcCount />} />
                            }
                             {userPermission["RC-Partner"]?.view == 1 &&
                            <Route path="/rc-partner" element={<RcPartner />} />
                            }
                             {userPermission["RC-Partner"]?.add == 1 &&
                            <Route path="/rc-partner/add" element={<RcPartnerAdd />} />
                            }
                             {userPermission["RC-Partner"]?.edit == 1 &&
                            <Route path="/rc-partner/edit/:id" element={<RcPartnerAdd />} />
                            }
                             {userPermission["RC-Partner"]?.view == 1 &&
                            <Route path="/rc-partner/view/:id" element={<RcPartnerView />} />
                            }
                             {userPermission["RC-Services"]?.view == 1 &&
                            <Route path="/rc-services" element={<RcServices />} />
                            }
                             {userPermission["RC-Services"]?.add == 1 &&
                            <Route path="/rc-services/add" element={<RcServicesAdd />} />
                            }
                             {userPermission["RC-Services"]?.edit == 1 &&
                            <Route path="/rc-services/edit/:id" element={<RcServicesAdd />} />
                            }
                             {userPermission["RC-Services"]?.view == 1 &&
                            <Route path="/rc-services/view/:id" element={<RcServicesView />} />
                            }
                            
                            {/* <Route path="/rc-exchange-report" element={<RcExchangeReport />} />
                            <Route path="/rc-exchange-report/add" element={<RcExchangeReportAdd />} />
                            <Route path="/rc-exchange-report/view" element={<RcExchangeReportView />} />
                            <Route path="/rc-exchange-report/carinfo-rc-count" element={<CarinfoCount />} /> */}
                            
                            <Route path="*" element={<PageNotFound />} />
                            <>
                                <Route path="/module" element={<Module />} />
                                <Route path="/module/add" element={<AddModule />} />
                                <Route path="/module/view/:id" element={<ViewModule />} />
                                <Route path="/module/edit/:id" element={<EditModule />} />

                                <Route path="/role" element={<Role />} />
                                <Route path="/role/add" element={<RoleAdd />} />
                                <Route path="/role/edit/:id" element={<RoleAdd />} />
                                <Route path="/role/view/:id" element={<ViewRole />} />

                                <Route path="/user" element={<User />} />
                                <Route path="/user/add" element={<AddUser />} />
                                <Route path="/user/view/:id" element={<ViewUser />} />
                                <Route path="/user/edit/:id" element={<EditUser />} />
                            </>

                        </Route>
                    </Routes>
                </BrowserRouter>
                <ToastContainer position='bottom-right' autoClose={500} />
            </>
        </>
    )
}

export default App;
